<template>
  <div>
    <b-overlay :show="loading">
      <div class="text-center" style="margin-top: 20px">
        <b-card>
          <validation-observer ref="pilihSekolah" #default="{ invalid }">
            <b-form class="text-center" @submit.prevent="formSubmitted">
              <validation-provider
                #default="{ errors }"
                name="Sekolah"
                rules="required"
                class="mt-4"
              >
                <span class="mb-3"
                  ><strong>Halo {{ user.name }}</strong
                  >, Selamat datang di Tryout {{ tryoutDetail.name }} Silakan
                  pilih sekolah kedinasan tujuan anda terlebih dahulu sebelum
                  memulai tryout</span
                >
                <br />
                <br />
                <center>
                  <b-form-group
                    class="w-100"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      style="width: 50%"
                      id="sekolah"
                      class="d-flex flex-column justify-content-center"
                      v-model="pilihSekolah"
                      :options="sekolahName"
                      label="text"
                      placeholder="Pilih Sekolah"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </center>
              </validation-provider>
              <b-button type="submit" variant="primary" :disabled="invalid">
                Simpan
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BForm,
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BForm,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BButton,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      pilihRumpun: "",
      pilihSekolah: null,
      pilihUniversitas: [],
      universitas_id: 0,
      pilihJurusan: [],
      nextTodoId: 1,
      universitas: null,
      items: [
        {
          id: 1,
          universitas: null,
          jurusan: null,
          jurusanName: [],
        },
      ],
      selectedContry: "",
      selectedLanguage: "",
      name: "",
      emailValue: "",
      PasswordValue: "",
      passwordCon: "",
      first_name: "",
      last_name: "",
      address: "",
      landMark: "",
      pincode: "",
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      city: "",
      required,
      email,
      sekolahName: [],
      loading: false,
    };
  },
  watch: {},
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  methods: {
    async fetchDataJurusan(index) {
      if (this.items[index].universitas != null) {
        this.items[index].jurusanName = await this.getDataJurusan(
          this.items[index].universitas.id
        );
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
        universitas: null,
        jurusan: null,
        jurusanName: [],
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem() {
      this.items.splice(this.items.length - 1, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    formSubmitted() {
      this.$refs.pilihSekolah.validate().then((success) => {
        if (success) {
          this.loading = true;
          let payload = {
            paket_id: this.tryoutDetail.id,
            school_id: this.pilihSekolah.value,
            user_id: this.user.id,
            jadwal_id: this.$route.query?.j,
          };
          this.$store
            .dispatch("ujian/saveSekolahSiswa", [payload])
            .then((res) => {
              this.loading = false;
              this.$router.push(
                `/tryout-detail-subtest?j=${this.$route.query.j}`
              );
            })
            .catch((err) => {
              this.loading = false;
              this.displayError(err);
              return false;
            });
        }
      });
    },
    getDataRumpun() {
      this.$store.dispatch("rumpun/index", {}).then((response) => {
        let rumpun = response.data.data;
        rumpun.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.rumpunName = rumpun;
      });
    },
    getDataSekolah() {
      let params = {
        is_official_school: 1,
      };
      this.$store.dispatch("sekolah/kedinasan", params).then((response) => {
        let sekolah = response.data.data;
        sekolah.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.sekolahName = sekolah;
      });
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined || !this.$route.query?.j) {
      this.$router.go(-1);
    }
    this.getDataSekolah();
  },
};
</script>
